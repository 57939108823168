<template>
  <img src="/img/logo.png" class="max-w-20" alt="logo" />
</template>

<script lang="ts" setup>
import { useInstanceLogoUrl } from "@/composition/apollo/config";

const { instanceLogoUrl } = useInstanceLogoUrl();

withDefaults(
  defineProps<{
    invert?: boolean;
  }>(),
  { invert: false }
);
</script>
